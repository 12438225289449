import SingletonDependency from 'Lib/SingletonDependency';
import { NotificationLevel } from 'Lib/Models/NotificationLevel';
import NotificationModel from 'Lib/Models/NotificationModel';

export type NotificationCallback = (notification: NotificationModel) => void;

export default class NotificationService extends SingletonDependency {
    private readonly notificationListeners = new Set<NotificationCallback>();

    public override onDestroy(): void {}

    public addNotificationCallback(callback: NotificationCallback): void {
        this.notificationListeners.add(callback);
    }

    public success(message: string): void {
        console.log('[NOTIFICATION] ' + message);
        this.sendNotification(message, NotificationLevel.Success);
    }

    public info(message: string): void {
        console.info('[NOTIFICATION] ' + message);
        this.sendNotification(message);
    }

    public error(message: string): void {
        console.error('[NOTIFICATION] ' + message);
        this.sendNotification(message, NotificationLevel.Error);
    }

    private sendNotification(message: string, level: NotificationLevel = NotificationLevel.Info): void {
        const notification = new NotificationModel(message, level);

        for (const callback of this.notificationListeners.values()) {
            callback(notification);
        }
    }
}
