import type DependencyContainer from 'Lib/DependencyContainer';
import type IDependency from 'Lib/IDependency';

export default class SingletonDependency implements IDependency {
    protected applicationContext: DependencyContainer;

    public constructor(applicationContext: DependencyContainer) {
        this.applicationContext = applicationContext;
    }

    public onDestroy(): void {}
}
