const colors = {
    primary: 'var(--primary-color)',
    secondary: 'var(--secondary-color)',
    negative: 'var(--negative-color)',
};

const textColors = {
    default: 'var(--default-text-color)',
    primary: 'var(--primary-text-color)',
    secondary: 'var(--secondary-text-color)',
    negative: 'var(--negative-text-color)',
    disabled: 'var(--disabled-text-color)',
};

const button = {
    borderRadius: 'var(--button-border-radius)',
    padding: 'var(--button-padding)',
    minWidth: 'var(--button-min-width)',
};

const column = {
    padding: 'var(--column-padding)',
    horizontalAlign: 'var(--column-horizontal-align)',
    verticalAlign: 'var(--column-vertical-align)',
    backgroundColor: 'var(--column-background-color)',
};

const row = {
    padding: 'var(--row-padding)',
    horizontalAlign: 'var(--row-horizontal-align)',
    verticalAlign: 'var(--row-vertical-align)',
    backgroundColor: 'var(--row-background-color)',
    wrap: 'var(--row-wrap)',
};

const grid = {
    verticalAlign: 'var(--grid-vertical-align)',
    horizontalAlign: 'var(--grid-horizontal-align)',
};

const spacing = {
    gap: 'var(--default-gap)',
    padding: 'var(--default-padding)',
};

const container = {
    padding: 'var(--container-padding)',
    margin: 'var(--container-margin)',
};

const divider = {
    color: 'var(--divider-color)',
};

const text = {
    size: 'var(--font-size)',
    family: 'var(--font-family)',
    weight: 'var(--font-weight)',
    textTransform: 'var(--text-transform)',
    padding: 'var(--text-padding)',
    lineHeight: 'var(--line-height)',
};

const title = {
    size: 'var(--title-font-size)',
    family: 'var(--font-family)',
    weight: 'var(--title-font-weight)',
};

const initial = 'initial';
const unset = 'unset';

export { colors, textColors, button, column, row, grid, spacing, container, divider, text, title, unset, initial };
