import type { Action } from 'svelte/types/runtime/action';

const applyStyle: Action<HTMLElement, Record<string, any>> = (node, valuesParam) => {
    const update = (values: Record<string, any> | undefined): void => {
        if (!values) return;
        for (const [key, value] of Object.entries(values)) {
            node.style[key as any] = value;
        }
    };

    update(valuesParam);

    return {
        update,
        destroy(): void {},
    };
};

export default applyStyle;
